.contestants-root .title {
  padding-bottom: 3vh;
}

.contestants {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}

.contestant-container {
  padding: 3vh 2%;
}

.contestant {
  padding: 5vh 2%;
}

@media (max-width: 1200px) {
  .contestants {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 900px) {
  .contestants {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 500px) {
  .contestants-root .title {
    padding-top: 20px;
  }
  .contestants {
    grid-template-columns: repeat(1, 1fr);
    padding: 10px;
  }
}

/* @media (max-width: 400px) {
  .contestants {
    grid-template-columns: repeat(1, 1fr);
  }
} */
