body,
html {
  font-family: "Roboto", sans-serif;
  background-color: #f8f8f8;
}

.card {
  /* width: 300px; */
  height: fit-content;
  display: block;
  margin: 10px;
  box-shadow: 10px 5px 40px 20px darken(#f8f8f8, 2%);
  transition: 0.25s;
  background-color: #fff;
  box-shadow: 0px 2px 2px rgb(218, 218, 219);
}

.card:hover {
  box-shadow: 10px 5px 40px 20px darken(#f8f8f8, 10%);
  cursor: pointer;
}
.event-container{
  display:flex;
  justify-content: center;
  flex-direction: column;
}
.card:hover .button-primary {
  transform: translate(10px, 0);
}

.card-header {
  height: 22vh;
  padding: 15px;
  background-size: cover;
  transition: 0.25s;
  color: #fff;
}

.card-header--title {
  text-transform: uppercase;
  margin: 0;
}

.card-body {
  padding: 15px;
  background-color: #fff;
}

.date {
  font-size: 15px;
  font-weight: 400;
  color: grey;
}
.card-title {
  line-height: 1;
  font-size:15px;
  font-weight:600;
}

.body-content {
  padding-bottom: 10px;
  font-size: 10px;
  line-height: 1.5;
  /* height: 10vh; */
}

.body-content span {
  font-size: 15px;
  font-weight: 600;
  color: grey;
}

.button-primary {
  border: none;
  box-shadow: none;
  font-family: inherit;
  background-color: transparent;
  color: #000;
  font-size: 15px;
  transition: 0.25s;
  cursor: pointer;
}
.button-primary i {
  padding-right: 4px;
}

.designer-link {
  color: #fff;
  text-align: center;
  font-size: 12px;
  text-decoration: none;
  display: block;
}

.small-body-text {
  font-size: 13px;
  color: #000;
}
.card-button{
  padding:10px;
  margin-top:20px;
  border:1px solid #044368;
  text-align: center;
  border-radius: 5px;
}
.card-button:hover{
  padding:10px;
  border:1px solid #044368;
  text-align: center;
  border-radius: 5px;
  background-color:#044368;
  color:#fff
}
.vote-btn{
  border:1px solid #044368;
  width:100%;
  color:#044368;
}
.ticket-btn{
   border:1px solid #044368;
  width:100%;
  color:#044368;
}

@media (max-width: 600px) {
  .body-content {
    line-height: 1.8;
  }
  .body-content span {
    font-size: 14px;
  }
  .card-body {
    margin-top: -10px;
  }
  .card {
    box-shadow: 10px 5px 40px 20px darken(#f8f8f8, 10%);
    /* width: 260px; */
  }
  .card-header{
    min-height: 35vh;
  }
}
