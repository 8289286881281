#introduction {
  /* padding: 5vh 5%; */
  /* margin: 0 -5px; */
  /* background: linear-gradient(#64e4e6, #9198e5); */
  /* background-attachment: fixed; */
}
.wave {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 70px;
  width: 100%;
  background: dodgerblue;
  z-index: -1;
}
.wave::before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100% 90%;
  width: 51%;
  height: 75px;
  background-color: white;
  right: 0px;
  top: 35px;
}
.wave::after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100% 90%;
  width: 51%;
  height: 75px;
  background-color: dodgerblue;
  left: -8px;
  top: 25px;
}

#introduction img {
  height: 50vh;
  width: 100%;
  border-radius: 3px;
}

#introduction .title {
  font-size: 36px;
  text-align: unset;
  max-width: 500px;
  margin-top: 10vh;
}

.intro-button {
  background-color: #1876d1;
  color: white;
  border: 1px solid #1876d1;
  padding: 10px 15px;
  margin: 10px 0;
  border-radius: 6px;
  cursor: pointer;
}

.intro-button:hover {
  background-color: #fff;
  color: #1876d1;
  border: 1px solid #1876d1;
}

.imgIntroductionRoot {
  color: #fff;
  padding: 0;
}

.imgIntroduction {
  margin-top: 20vh;
  position: absolute;
  z-index: 1;
  padding: 0 10%;
}

.imgIntroductionTitle {
  font-size: 52px;
  width: 50%;
  padding: 5vh 0;
}
@media (max-width: 890px) {
  #introduction {
    display: flex;
    flex-direction: column-reverse;
  }
}
@media (max-width: 600px) {
  #introduction {
    /* padding: 5vh 5%; */
    /* padding-top:5vh; */
    margin: 0px;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column-reverse;
    padding-top: 20px;
  }
  #introduction img {
    height: 40vh;
    display: block;
    width: 100%;
  }

  #introduction .title {
    margin-top: 10px;
  }

  .imgIntroductionTitle {
    font-size: 32px;
    width: unset;
  }

  .imgIntroduction .button {
    display: inline;
  }
  .imgIntroduction {
    margin-top: 15vh;
    padding-left: 10%;
  }
  .imgIntroduction img {
    width: 100%;
  }
}
